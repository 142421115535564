import { Injectable } from '@angular/core';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { ToolbarComponent } from './toolbar.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Platform } from '@angular/cdk/platform';
const mobile = require('is-mobile');

@Injectable({
  providedIn: 'root'
})
export class PwaService {
  private promptEvent: any;
  status = mobile();

  constructor() { }

  public initPwaPrompt() 
  {
      window.addEventListener('beforeinstallprompt', (event: any) => {
          event.preventDefault();
          this.promptEvent = event;
          this.setButton(true);
      });
  }

  public setButton(arr) {
      return this.status = arr;
  }

  public installPwa() {
      this.initPwaPrompt();
      this.setButton(false);
      this.promptEvent.prompt();
      this.promptEvent.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
              console.log('User mengizinkan install pwa');
          } else {
              console.log('User menolak install pwa');
          }
          this.promptEvent = null;
      });
  }
}
