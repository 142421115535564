import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { GlobalService, GlobalVariables, QBErrorPostObj, ColorType } from './global.service';
// Add the RxJS Observable operators we need in this app.
import { Observable, of } from 'rxjs';
// import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { catchError, tap, retry } from 'rxjs/operators';
import { QBResponsePacket } from '../shared/qb-response-packet.class';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationService } from './notification.service'

import icCheckCircle from '@iconify/icons-ic/twotone-check-circle';
import icWarning from '@iconify/icons-ic/twotone-warning';
import icAccountCircle from '@iconify/icons-ic/twotone-account-circle';
import icError from '@iconify/icons-ic/twotone-error';



export const httpOptions = {
  // headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=windows-1255' })

};

export interface QBRequestData {
  url: string;
  body: Object;
  httpOptions?: Object;
  retry?: number;
  [propName: string]: any;
}

// export class QBResponsePacket {
//   status: string;
//   statusDescription?: string;
//   data?: Object;
//   rowCount?: string;
//   time?: number;//server timestamp
//   [propName: string]: any;
//   constructor(obj?: Object) {
//     if (typeof obj != 'object') {
//       throw new TypeError('Invalid object');
//     }
//     Object.assign(this, JSON.parse(JSON.stringify(obj)));
//   }
// }

export interface QBClientService {
  url: string;
}

export class QBClientServiceClass {
  private static lastSuccessServerTime: number | string = '00001';
  private static versionTime = '';
  private static sessionId = '';
  private static intErrorNotified = false;
  protected authUrl: string;
  protected errUrl: string;

  constructor(protected http: HttpClient, protected glob: GlobalService,
    protected notificationService: NotificationService,
    protected router?: Router,
    protected sBar?: MatSnackBar,
  ) {
    // QBClientServiceClass.sessionId = localStorage.getItem('sessId');
    QBClientServiceClass.sessionId = sessionStorage.getItem('access_token');
    // this.authUrl = this.globals.backendURL+'authentication';
    this.errUrl = this.globals.backendURL + 'error_report';
  }



  get globals(): GlobalVariables { return this.glob.getVars(); }
  get httpDebug(): boolean { return this.glob.debug.http; }
  get errDebug(): boolean { return this.glob.debug.errorHandling; }
  get debug(): boolean { return (this.httpDebug || this.errDebug); }
  get navigaite() { return this.router; }

  logout1(triggerer: string): Observable<QBResponsePacket> {
    // this.globals.currentUser = null;
    if (sessionStorage.getItem('uData') == 'LOGGEDOUT') return;
    sessionStorage.setItem('uData', 'LOGGEDOUT');
    return this.httpPost({ url: this.authUrl + '/logout', body: { triggerer } });
  }

  httpPost(req: QBRequestData): Observable<QBResponsePacket> {

    if (!req.httpOptions) req.httpOptions = httpOptions;
    if (!(req.body instanceof Object)) req.body = {};
    req.body['versionTime'] = QBClientServiceClass.versionTime || '';
    req.body['sessionId'] = QBClientServiceClass.sessionId || '';
    // req.body['siteId'] = localStorage.getItem('siteId') || '';

    // console.log('req.body', req.body)
    // console.log('backendURL', this.globals.backendURL + req.url)

    return this.http.post<QBResponsePacket>(this.globals.backendURL + req.url, req.body, req.httpOptions)
      .pipe(
        // retry(req.retry || 2),
        tap(res => this.checkReply(res)),
        catchError(this.handleError(req, new QBResponsePacket('HTTP_ERROR')))
      );
  }

  checkReply(res: QBResponsePacket) {
    // QBClientServiceClass.lastSuccessServerTime = res.time;
    // QBClientServiceClass.versionTime = res.versionTime;
    // QBClientServiceClass.sessionId = res.sessionId;
    // localStorage.setItem('sessId', res.sessionId);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param req - request passed to httpPost()
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(req: QBRequestData, result?: T) {//https://angular.io/tutorial/toh-pt6#error-handling
    return (error: any): Observable<T> => {

      if (this.debug) console.log('QBClientServiceClass.handleError:\n  error:\n  ', error);
      // console.log(error);
      // console.log(req);
      if(window.location.href.includes("menuPage=9000")){
       let time = Date.now();
        this.glob.notify({
          label: ` בצע חיבור לאינטרנט ונסה שוב  `,
          colorClass: ColorType.warning,
          icon: icWarning,
          showSnackbar: true
        });
      }
     else if (error.status == 401) {
        this.notifyLoginError();
        // console.log(this);
        this.navigaite.navigate(['/login']);
      }  
      else
      {

        this.notifyConnectionError();
        this.reportHttpError(req, error);
      }

      this.glob.alert('הפניה לשרת נכשלה. ייתכן ומדובר בתקלה בשרת. אם הבעיה ממשיכה, נא לפנות לתמיכה הטכנית.\nSRV00001');

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  reportHttpError(req: QBRequestData, error: Object | HttpErrorResponse) {
    if (this.debug) console.log('QBClientServiceClass.reportHttpError:\n  req:\n  ', req, '\n  error\n  ', error);
    let err = new QBErrorPostObj();
    err.errorObject = error;
    err.message = error['message'];
    err.request = req;
    if (error instanceof HttpErrorResponse) {
      err.source = 'server';
      err.status = error.status;
    } else {
      err.source = 'http';
    }
    this.httpPostError(err);
  }

  httpPostError(errorPostObj: QBErrorPostObj) {
    // console.log(errorPostObj);

    if (this.debug) console.log('QBClientServiceClass.httpPostError:\n  errorPostObj:\n  ', errorPostObj);
    // if (location.hostname == 'localhost') return;
    // errorPostObj.globalVariables = this.globals;
    this.http.post(this.errUrl + '/client_error', errorPostObj, httpOptions)
      .subscribe(null, error => {
        // console.log('QBClientServiceClass.httpPostError > catchError:\n  error:\n  ', error);


        if (error && error.status > 0) {
          // this.glob.alert(`סטטוס שגיאה התקבל מהשרת. ייתכן ומדובר בתקלה בשרת. אם הבעיה ממשיכה, נא לפנות לתמיכה הטכנית.\n<b>REP${error.status}</b>`);
          // alert(`סטטוס שגיאה התקבל מהשרת. ייתכן ומדובר בתקלה בשרת. אם הבעיה ממשיכה, נא לפנות לתמיכה הטכנית.\n<b>REP${error.status}</b>`);


          // this.sBar.open('סטטוס שגיאה התקבל מהשרת. ייתכן ומדובר בתקלה בשרת. אם הבעיה ממשיכה, נא לפנות לתמיכה הטכנית', ' סגור', {
          //   duration: 10000
          // });


          let time = Date.now();
          this.glob.notify({
            label: ` חלה__ שגיאה בממשק משתמש , במידה והבעיה ממשיכה מומלץ לסגור את הדפדפן שגיאה מס: ${time} `,
            colorClass: ColorType.warning,
            icon: icWarning,
            showSnackbar: true
          });


        } else {
          console.log('errorPostObj');
          this.notifyConnectionError();
        }
      });
  }

  notifyConnectionError() {
    if (!QBClientServiceClass.intErrorNotified) {
      QBClientServiceClass.intErrorNotified = true;
      let timeout = 5;
      // this.glob.notify(`חיבור לשרת נכשל, נא לוודא חיבור לאינטרנט.\n<b>INT${QBClientServiceClass.lastSuccessServerTime}</b>`, {type: 'danger', timeout});

      // this.glob.alert(`חיבור לשרת נכשל, נא לוודא חיבור לאינטרנט.\n<b>INT${QBClientServiceClass.lastSuccessServerTime}</b>`);
      // alert(`חיבור לשרת נכשל, נא לוודא חיבור לאינטרנט.\n<b>INT${QBClientServiceClass.lastSuccessServerTime}</b>`);
      // this.sBar.open('חיבור לשרת נכשל, נא לוודא חיבור לאינטרנט.\n<b>INT${QBClientServiceClass.lastSuccessServerTime}</b>', ' סגור', {
      //   duration: 10000
      // });


      this.glob.notify({
        label: `חיבור לשרת נכשל, נא לוודא חיבור לאינטרנט.\nINT${QBClientServiceClass.lastSuccessServerTime}`,
        colorClass: ColorType.error,
        icon: icError,
        showSnackbar: true
      });

      setTimeout(() => QBClientServiceClass.intErrorNotified = false, timeout * 1000);
    }
  }

  notifyLoginError() {
    if (!QBClientServiceClass.intErrorNotified) {
      QBClientServiceClass.intErrorNotified = true;
      let timeout = 5;


      this.glob.notify({
        label: ` סיסמה או משתמש לא נכונים או שפג תוקף החיבור`,
        colorClass: ColorType.error,
        icon: icError,
        showSnackbar: true
      });
      setTimeout(() => QBClientServiceClass.intErrorNotified = false, timeout * 1000);

    }
  }
}
