import { Injectable, ErrorHandler } from '@angular/core';
import { ErrorReportService } from './error-report.service';

/**
* Override angular's ErrorHandler and send the log to backend.
*/
@Injectable() // This decorator is necessary for Angular to be able to inject this service
export class CustomErrorHandler implements ErrorHandler {

  constructor(private err: ErrorReportService) { }

  handleError(error: any) {
    if (error.message.includes("[Iconify]")) {
      console.warn('Iconify Error Handled:', error.message);
      return;
    }

    console.error(error);
    if (typeof error == 'object' && error.message && !window.location.href.includes("menuPage=9000")) {
      this.err.reportFrontendError({ exception: error });
    } else if (!window.location.href.includes("menuPage=9000")) {
      this.err.reportFrontendError({ message: error });
    }
  }
}
