
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { QBResponsePacket } from '../../../shared/qb-response-packet.class';
import { QBClientServiceClass } from '../../../services/qb-client.service';
import { GlobalService, NotificationService } from '../../../services/global.service';
import { Router } from '@angular/router';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
    //   'Authorization': 'jwt-token'
  })
};

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends QBClientServiceClass {

  private url = 'dashboard';

  constructor(protected http: HttpClient, protected glob: GlobalService, protected notificationService: NotificationService, protected router: Router) {
    super(http, glob, notificationService);
  }



  getTransmission(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-transmission', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }

  getHatFarms(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-hat-farms', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }

  getHatCycle(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-hat-cycle', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }

  getFarms(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-farms', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }

  getCycle(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-cycle', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  getCare(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-care', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  getHatCare(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-hat-care', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  getRfId(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-rf-id', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }

  GetFarmData(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-farm-data', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  getDoctors(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-doctors', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }

  getHatDoctors(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-hat-doctors', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }

  getHatProcedurs(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-hat-procedurs', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }

  getHatMarketingComp(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-hat-marketing-comp', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  getFoodCenter(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-food-center', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }

  saveDailyDataCollection(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/save-daily-data-collection', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }

  saveMakingMixture(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/save-making-mixture', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }

  saveEggMarketing(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/save-egg-marketing', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }

  saveBikoretFarm(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/save-bikoret-farm', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }

  saveVeterinaryTipul(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/save-veterinary-tipul', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }

  saveDataLeaka(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/save-data-leaka', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }

  saveFoodDemand(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/save-food-demand', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  saveFarmReview(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/save-farm-review', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  saveVeterinaryCare(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/save-veterinary-care', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  sendWeightData(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/send-weight-data', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  GetAppOrdPrtList(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-app-ord-prt-list', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  getReportLeaka(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-report-leaka', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  GetFullDayDataRep(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-full-day-data-rep', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  getDayGraph(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-day-graph', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  getWeekGraph(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-week-graph', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  getMonthGraph(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-month-graph', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  getLast14DaysData(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-last-14-days-data', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  GetEderDashBoardData(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-eder-dashBoard-data', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  GetFeedInRep(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-feed-in-rep', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  GetMarketRep(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-market-rep', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  GetWeightRep(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-weight-rep', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  GetAppTOrdShipList(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-app-t-ord-ship-list', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  GetSDashBoardData(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-s-dash-board-data', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  GetHatVetRep(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-hat-vet-rep', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  GetHatVisitRep(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-hat-visit-rep', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  GetCalRepData(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-cal-rep-data', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  GetFeedRepData(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-feed-rep-data', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  GetLastF1001Data(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-last=f1001-data', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  GetBodyWeightRep(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-body-weight-rep', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  GetStockDataRep(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-stock-data-rep', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  GetSortDataRep(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-sort-data-rep', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  GetGenDashBoardData(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-gen-dashboard-data', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }

  feedCustomersList(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/feed-customers-list', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  getCustomerDataFeed(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-customer-data-feed', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  getListDrishHovala(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-list-drish-hovala', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  getListSugMasait(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-list-sug-masait', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }

  getLakohotDrishot(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-lakohot-drishot', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  getKartestLakoah(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-kartest-lakoah', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  getReportLakohOrder(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-report-lakoh-order', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  getReportTeudatMishloah(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-report-teudat-mishloah', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  getListHomarim(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-list-homarim', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }





  // !!!!!!!!!!!!!
  feedBuildCustomerData(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/feed-build-customer-data', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  feedBuildAllCustomersData(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/feed-build-all-customers-data', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }



  FeedBuildCustomerHmrTable(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/feed-build-customer-hmr-table', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  feedDeleteOrder(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/feed-delete-order', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  feedSaveNewOrder(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/feed-save-new-order', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }
  feedGetCusWaitingOrders(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/feed-get-cus-waiting-orders', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }

  /////snir-new function
  getNotification(params?: {} & { [prop: string]: any }): Observable<any[]> {
    return this.httpPost({ url: this.url + '/get-notification', body: params || {} }).pipe(map((response: QBResponsePacket) => response.data || ''));
  }




}





