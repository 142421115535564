<div (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()" [class.collapsed]="collapsed"
  [class.open]="collapsed && collapsedOpen$ | async" class="sidenav flex flex-col">
  <div class="sidenav-toolbar flex-none flex items-center logo-bg">
    <!-- <img class="w-6 select-none flex-none" src="assets/img/demo/logo.svg">
    <h2 class="title ltr:pl-4 rtl:pr-4 select-none flex-auto">אורשר</h2> -->
    <!-- <img class="w-6 select-none flex-none logo" src="assets\img\bokrim-logo.jpg"> -->

    <!-- <h2 class="ltr:pl-6 rtl:pr-6 select-none flex-auto">תדמיר 0.2</h2> -->
    <button (click)="toggleCollapse()" class="w-8 h-8 -mr-2 leading-none flex-none" mat-icon-button type="button">
      <mat-icon *ngIf="!collapsed" [icIcon]="icRadioButtonChecked" size="14px"></mat-icon>
      <mat-icon *ngIf="collapsed" [icIcon]="icRadioButtonUnchecked" size="14px"></mat-icon>
    </button>

  </div>

  <img [src]="logoPath" class="logo-sidnav" width="90%" height="auto" style="margin: auto;">

  <div class="sidenav-items flex-auto" vexScrollbar>

    <vex-sidenav-item (click)="reloadComponent()" *ngFor="let item of items; trackBy: trackByRoute" [item]="item"
      [level]="0">
    </vex-sidenav-item>
    <!-- <button class="mr-5" (click)="openContainer()" color="primary" mat-raised-button type="button">
      <ic-icon [icon]="icPlus" inline="true" size="18px"></ic-icon>הוספת מכולה
    </button> -->
  </div>
</div>