<div class="mediaPrint toolbar w-full px-gutter"
 style="display: flex;
     justify-content: space-between;

 " vexContainer>
  
  <div style="display: flex;
" >
  <button (click)="openSidenav()"  mat-icon-button type="button">
    <mat-icon class="matMenu" [icIcon]="icMenu"></mat-icon>

  </button>
  <div class="farm_cycle" *ngIf="!(numUrl>=0)" dir="rtl" >  
    <h5>{{mysfarmStr }} </h5> 
    <h5>{{myscycleStr}} </h5>
   
  </div>
</div>
 
  <!-- <div *ngIf="mob">
    <button *ngIf="data.promptEvent" (click)="installPwa()">Install</button>

  </div> -->
<!-- ניסיון!!!!!! -->
<!-- <div *ngIf="mob" color="primary" class="android-prompt">
  <button mat-button (click)="installPwa()">
    Add to Home screen
  </button>
  <button mat-icon-button (click)="close()">
    <mat-icon [icIcon]="icClose"></mat-icon>
  </button>
</div>
<div *ngIf="data.mobileType === 'ios'" class="ios-prompt">
  <button class="close-ios-inv" mat-button color="primary" (click)="close()"><mat-icon [icIcon]="icClose"></mat-icon>  </button>
  <div>To install this web app on your device tap the Menu button and then 'Add to Home screen' button</div>
  <div><img src="./assets/ios-menu-btn.png"> <mat-icon>navigate_next</mat-icon> <img src="./assets/ios-add-btn.png"></div>
</div> -->


  <div><h1 >{{tit()}}</h1></div>
  <!-- 
  <a *ngIf="isVerticalLayout$ | async"
     [routerLink]="['/']"
     class="ltr:mr-4 rtl:ml-4 block"
     fxLayout="row"
     style="align-items: flex-start; align-content: center">
    <img class="w-8 select-none" src="assets/img/demo/logo.svg">
    <h1 [fxHide]="mobileQuery" class="title ltr:pl-4 rtl:pr-4 m-0 select-none">VEX</h1>
  </a> -->

  <!-- <button *ngIf="isHorizontalLayout$ | async"
          [fxHide]="mobileQuery"
          [matMenuTriggerFor]="addNewMenu"
          color="primary"
          mat-raised-button
          type="button">ADD NEW
  </button> -->

  <!-- <mat-menu #addNewMenu="matMenu" [overlapTrigger]="false" xPosition="after" yPosition="below">
    <button mat-menu-item>
      <mat-icon [icIcon]="icPersonAdd"></mat-icon>
      <span>Add Contact</span>
    </button>

    <button mat-menu-item>
      <mat-icon [icIcon]="icDoneAll"></mat-icon>
      <span>Add Task</span>
    </button>

    <button mat-menu-item>
      <mat-icon [icIcon]="icAssignmentTurnedIn"></mat-icon>
      <span>Add Project</span>
    </button>

    <button [matMenuTriggerFor]="documentMenu" mat-menu-item>
      <mat-icon [icIcon]="icBallot"></mat-icon>
      <span>Add Document</span>
    </button>
  </mat-menu>

  <mat-menu #documentMenu="matMenu">
    <button mat-menu-item>
      <mat-icon [icIcon]="icDescription"></mat-icon>
      <span>Add Quote</span>
    </button>

    <button mat-menu-item>
      <mat-icon [icIcon]="icAssignment"></mat-icon>
      <span>Add Invoice</span>
    </button>

    <button mat-menu-item>
      <mat-icon [icIcon]="icReceipt"></mat-icon>
      <span>Add Receipt</span>
    </button>
  </mat-menu> -->

  <!-- <div #megaMenuOriginRef class="ltr:ml-2 rtl:mr-2">
    <button *ngIf="isHorizontalLayout$ | async"
            [fxHide]="mobileQuery"
            (click)="openMegaMenu(megaMenuOriginRef)"
            color="primary"
            mat-button
            type="button">MEGA MENU
      <ic-icon [icon]="icArrowDropDown" class="ltr:-mr-1 rtl:-ml-1" inline="true"></ic-icon>
    </button>
  </div> -->


  <!-- <button class="mr-5" (click)="openContainer()" color="primary" mat-raised-button type="button">
    <ic-icon [icon]="icPlus" inline="true" size="18px"></ic-icon>הוספת מכולה
  </button> -->



  <!-- 
  <div *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async"
       [fxHide]="mobileQuery"
       class="px-gutter"
       fxFlex="none"
       fxLayout="row"
       style="align-items: flex-start; align-content: center">
    <vex-navigation-item *ngFor="let item of navigationItems" [item]="item"></vex-navigation-item>
  </div> -->

  <span fxFlex></span>




  <!-- <button (click)="pushNote()" color="primary" mat-raised-button type="button">Primary</button> -->

  <!-- <div class="-mx-1 flex items-center"> -->
  <!-- <div class="px-1">
    <button (click)="openSearch()" mat-icon-button type="button">
      <mat-icon class="icSearch" [icIcon]="icSearch"></mat-icon>
    </button>
  </div> -->
  <div style="display: flex;
 margin-top: 10px; ">
   <div  class="px-1" *ngIf="this.pwa.status">   
    <button (click)="this.pwa.installPwa()"> <mat-icon [icIcon]="icCloudDownload" class="text-secondary"></mat-icon></button>
</div>
  <div class="px-1">
    <vex-toolbar-notifications></vex-toolbar-notifications>
  </div>
  <div class="px-1">
    <vex-toolbar-user></vex-toolbar-user>
  </div>
</div>
  <!-- 
    <div class="px-1">
      <button (click)="openQuickpanel()" mat-icon-button type="button">
        <mat-icon [icIcon]="icBookmarks"></mat-icon>
      </button>
    </div> -->

  <!-- <div class="px-1">
      <button [matMenuTriggerFor]="languageMenu" mat-icon-button type="button">
        <mat-icon [icIcon]="emojioneUS"></mat-icon>
      </button>
    </div> -->



  <!-- <mat-menu #languageMenu="matMenu" overlapTrigger="false" xPosition="before" yPosition="below">
      <button mat-menu-item>
        <mat-icon [icIcon]="emojioneUS"></mat-icon>
        <span>English</span>
      </button>

      <button mat-menu-item>
        <mat-icon [icIcon]="emojioneDE"></mat-icon>
        <span>German</span>
      </button>
    </mat-menu>
  </div>
</div> -->

  <vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async" >
  </vex-navigation>